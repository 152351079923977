import {configureStore} from '@reduxjs/toolkit'

import toastSlice from './data/toast-slice'
import dataSlice from './data/data-slice'
import blockchainSlice from './blockchain/blockchain-slice'
import nftSlice from './nft/nft-slice'

export const store = configureStore({
  reducer: {
    toast: toastSlice,
    data: dataSlice,
    blockchain: blockchainSlice,
    nft: nftSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
