import axios from 'axios'
import {
  loadNFTs,
  loadCollections,
  bindedAddressLoading,
  bindedAddressLoaded,
  walletAddressNew,
  walletAddressInitialized,
  loadCollection,
  loadNFT,
} from './nft-slice'

const API_URL = process.env.REACT_APP_API_URL

export const fetchEditorNFT = (
  address: string,
  collectionId: string,
  paginator: any,
  sorting: any,
  searchTerm: string
) => {
  const body = {
    address: address,
    collectionId: collectionId,
    paginator: paginator,
    sorting: sorting,
    searchTerm: searchTerm,
  }

  return async (dispatch: any) => {
    const retrieveData = async () => {
      const response = await axios.post(`${API_URL}/product/get-by-editor`, body)

      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }
      return response.data
    }

    try {
      const data = await retrieveData()
      dispatch(loadNFTs(data))
    } catch (err) {
      console.log(err)
      dispatch(loadNFTs(null))
    }
  }
}

export const fetchOwnerNFT = (address: string, limit: number, cursor: number) => {
  return async (dispatch: any) => {
    const retrieveData = async () => {
      const response = await axios.get(
        `${API_URL}/product/get-by-owner?address=` + address + '&limit=' + limit
      )
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }
      return response.data
    }

    try {
      const data = await retrieveData()
      dispatch(loadNFTs(data))
    } catch (err) {
      console.log(err)
      dispatch(loadNFTs(null))
    }
  }
}

export const fetchEditorCollections = (address: string) => {
  return async (dispatch: any) => {
    const retrieveData = async () => {
      const response = await axios.get(`${API_URL}/collection/get/${address}`)
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await retrieveData()
      dispatch(loadCollections(data.result))
    } catch (err) {
      console.log(err)
      dispatch(loadCollections(null))
    }
  }
}

export const fetchBindedAddress = () => {
  return async (dispatch: any) => {
    dispatch(bindedAddressLoading())
    const retrieveData = async () => {
      const response = await axios.get(`${API_URL}/web3/get-address`)
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await retrieveData()
      dispatch(bindedAddressLoaded(data.result))
    } catch (err) {
      dispatch(bindedAddressLoaded(null))
    }
  }
}

export const unbindAddress = (id: string) => {
  return async (dispatch: any) => {
    dispatch(bindedAddressLoading())
    const retrieveData = async () => {
      const response = await axios.delete(`${API_URL}/web3/address/${id}`)
      if (response.status !== 200) {
        throw new Error('Failed to delete address')
      }

      return response.data
    }

    try {
      await retrieveData()
      dispatch(fetchBindedAddress())
    } catch (err) {}
  }
}

export const verifyWalletAddress = (address: string) => {
  return async (dispatch: any) => {
    const verify = async () => {
      const response = await axios.post(`${API_URL}/web3/wallet-verify`, {address})
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await verify()
      if (data.result.init_status === 'none') {
        dispatch(walletAddressNew())
      } else if (data.result.init_status === 'done') {
        dispatch(walletAddressInitialized())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const initAssets = (address: string) => {
  return async (dispatch: any) => {
    const init = async () => {
      const response = await axios.post(`${API_URL}/product/init-products`, {address})
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      await init()
      // dispatch(actions.loadNFTs(data))
    } catch (err) {
      console.log(err)
      // dispatch(actions.loadNFTs(null))
    }
  }
}

export const fetchCollection = (address: string, collectionId: string) => {
  return async (dispatch: any) => {
    const load = async () => {
      const response = await axios.get(`${API_URL}/collection/get/${address}/${collectionId}`)
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await load()
      dispatch(loadCollection(data.result))
    } catch (err) {
      console.log(err)
      dispatch(loadCollection(null))
    }
  }
}

export const fetchNFT = (address: string, nftId: string) => {
  return async (dispatch: any) => {
    const load = async () => {
      const response = await axios.get(`${API_URL}/product//get-by-owner/${address}/${nftId}`)
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await load()
      dispatch(loadNFT(data.result))
    } catch (err) {
      console.log(err)
      dispatch(loadNFT(null))
    }
  }
}
