import {createSlice} from '@reduxjs/toolkit'

interface blockchainState {
  loading: boolean
  account: string | null
  web3: string | null
  errorMsg: string
  networkSupported: boolean
  addressValid: boolean
}

const initialState: blockchainState = {
  loading: false,
  account: null,
  web3: null,
  errorMsg: '',
  networkSupported: false,
  addressValid: false,
}

const blockchainSlice = createSlice({
  name: 'blockchain',
  initialState: initialState,
  reducers: {
    connectRequest(state) {
      state.loading = true
    },
    connectSuccess(state, action) {
      state.loading = false
      state.account = action.payload.account
      state.web3 = action.payload.web3
    },
    connectFailed(state, action) {
      state.loading = false
      state.errorMsg = action.payload
    },
    setChainSupported(state) {
      state.networkSupported = true
    },
    setChainNotSupported(state) {
      state.networkSupported = false
    },
    setAddressValid(state) {
      state.addressValid = true
    },
    setAddressInvalid(state) {
      state.addressValid = false
    },
    updateAccountRequest(state, action) {
      state.account = action.payload.account
    },
  },
})

export const {
  connectRequest,
  connectSuccess,
  connectFailed,
  setChainSupported,
  setChainNotSupported,
  setAddressValid,
  setAddressInvalid,
  updateAccountRequest,
} = blockchainSlice.actions

export default blockchainSlice.reducer
