import {fetchDataRequest, fetchDataSuccess, fetchDataFailed} from './data-slice'

export const fetchData = (account: string) => {
  return async (dispatch: any) => {
    dispatch(fetchDataRequest())
    try {
      // let name = await store.getState().blockchain.smartContract.methods.name().call()

      dispatch(fetchDataSuccess())
    } catch (err) {
      console.log(err)
      dispatch(fetchDataFailed('Could not load data from contract.'))
    }
  }
}
