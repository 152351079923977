import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {Card} from 'react-bootstrap'
import NFTConfigModal from './modals/nftConfigModal'
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {StatusMessage} from '../../modules/errors/components/StatusMessage'
import CustomPagination from '../../modules/widgets/CustomPagination'
import {fetchCollection, fetchEditorNFT} from '../../store/nft/nft-actions'
import {useAppSelector, useAppDispatch} from '../../store/hook'

const EditorNFTListWrapper: FC = () => {
  const [modalShow, setModalShow] = React.useState(false)

  const params = useParams()
  const collectionId = params.collectionId ? params.collectionId.toString() : ''
  const dispatch = useAppDispatch()
  const nft: any = useAppSelector((state) => state.nft)
  const bc: any = useAppSelector((state) => state.blockchain)
  let timer: NodeJS.Timeout | null = null

  const pageSize = 50
  const [currPage, setCurrPage] = React.useState(1)
  const [totPage, setTotPage] = React.useState(10)
  const [searchText, setSearchText] = React.useState('')

  const paginator = {pageSize: pageSize, page: currPage}
  const sorting = {column: 'token_id', direction: 'asc'}

  useEffect(() => {
    if (bc.account) {
      dispatch(fetchCollection(bc.account, collectionId))
      dispatch(fetchEditorNFT(bc.account, collectionId, paginator, sorting, searchText))
    }
  }, [bc])

  const handleSearch = (event: any) => {
    setCurrPage(1)
    paginator.page = 1
    dispatch(fetchEditorNFT(bc.account, collectionId, paginator, sorting, event.target.value))
  }

  const afterPageClicked = (page_number: number) => {
    setCurrPage(page_number)
    paginator.page = page_number

    dispatch(fetchEditorNFT(bc.account, collectionId, paginator, sorting, searchText))
  }

  const intl = useIntl()
  return (
    <>
      <StatusMessage />
      {bc.networkSupported && bc.addressValid && (
        <>
          {!nft.collection && (
            <div className='alert alert-danger' role='alert'>
              Invalid Collection
            </div>
          )}
          {nft.collection && (
            <div>
              <PageTitle breadcrumbs={[]}>{nft.collection.name}</PageTitle>
              <div className='row p-5'>
                <div className='col-8'>
                  <input
                    type='text'
                    className='form-control form-control-solid p-7'
                    name='search'
                    placeholder='Search by nft name'
                    onChange={handleSearch}
                  />
                </div>
                <div className='col-4'>
                  <Card>
                    <Card.Body>Total Sale: USD 3087 *dummy</Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          )}
          <div className='row'>
            {nft.assets.length === 0 && (
              <div className='alert alert-info' role='alert'>
                No supported NFTs found in this collection
              </div>
            )}
            {nft.collection &&
              nft.assets.map((item: any) => (
                <div className='col-12 col-md-6 col-lg-4 p-5' key={item._id}>
                  <div className=' d-flex justify-content-center'>
                    <img src={item.image_url} width='100%' alt='' />
                  </div>
                  <div className='row'>
                    <div className='col-5 pt-3'>
                      <h6 className='pt-3'>{item.name}</h6>
                    </div>
                    <div className='col-7 d-flex justify-content-end'>
                      <div
                        className='btn btn-icon btn-light-dark btn-sm mt-3'
                        onClick={() => setModalShow(true)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/coding/cod001.svg'
                          className='svg-icon-1'
                        />
                      </div>
                      <div className='m-2 pt-2'>
                        <div className='form-check form-check-solid form-switch fv-row'>
                          <input
                            className='form-check-input w-45px h-30px'
                            type='checkbox'
                            id='allowmarketing'
                            defaultChecked={true}
                          />
                          <label className='form-check-label pt-1'>On Sale</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <CustomPagination
            totPages={totPage}
            currentPage={currPage}
            pageClicked={(ele: number) => {
              afterPageClicked(ele)
            }}
          ></CustomPagination>
          <NFTConfigModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
      )}
    </>
  )
}

export {EditorNFTListWrapper}
