import {createSlice} from '@reduxjs/toolkit'
// import type {RootState} from '../store'

interface nftState {
  isLoading: boolean
  walletInitialized: boolean | null
  next: number | null
  previous: number | null
  assets: []
  assetsTotal: number
  collections: []
  addresses: []
  asset: any | null
  collection: any | null
}

const initialState: nftState = {
  isLoading: false,
  walletInitialized: null,
  next: null,
  previous: null,
  assets: [],
  assetsTotal: 0,
  collections: [],
  addresses: [],
  asset: null,
  collection: null,
}

const nftSlice = createSlice({
  name: 'nft',
  initialState: initialState,
  reducers: {
    loadNFTs(state, action) {
      state.next = null
      state.previous = null
      state.assets = action.payload ? action.payload.items : []
      state.assetsTotal = action.payload ? action.payload.total : 0
    },
    loadCollections(state, action) {
      state.collections = action.payload ? action.payload : []
    },
    loadNFT(state, action) {
      state.asset = action.payload ? action.payload : null
    },
    loadCollection(state, action) {
      state.collection = action.payload ? action.payload : null
    },
    walletAddressNew(state) {
      state.walletInitialized = false
    },
    walletAddressInitialized(state) {
      state.walletInitialized = true
    },
    bindedAddressLoading(state) {
      state.isLoading = true
    },
    bindedAddressLoaded(state, action) {
      state.isLoading = false
      state.addresses = action.payload ? action.payload : []
    },
  },
})

export const {
  loadNFTs,
  loadCollections,
  loadNFT,
  loadCollection,
  walletAddressNew,
  walletAddressInitialized,
  bindedAddressLoading,
  bindedAddressLoaded,
} = nftSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default nftSlice.reducer
