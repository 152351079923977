/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {connect, init} from '../../../../app/store/blockchain/blockchain-actions'
import {fetchData} from '../../../../app/store/data/data-actions'
import {useAppDispatch, useAppSelector} from '../../../../app/store/hook'
import {useAuth} from '../../../../app/modules/auth'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const dispatch = useAppDispatch()
  const bc: any = useAppSelector((state) => state.blockchain)
  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {currentUser} = useAuth()

  useEffect(() => {
    if (bc.account !== '') {
      dispatch(fetchData(bc.account))
    }
  }, [bc.account, dispatch])

  useEffect(() => {
    dispatch(init())
  }, [dispatch])

  const web3ConnectHandler = (e: any) => {
    e.preventDefault()
    dispatch(connect())
  }

  const shorttenAdd = () => {
    let addr = bc.account.slice(0, 4) + '...' + bc.account.slice(-4)
    return addr
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* WEB3 BUTTON */}
        <div>
          {!bc.account && (
            <button
              onClick={web3ConnectHandler}
              className='btn btn-custom btn-active-muted btn-flex btn-color-white btn-active-color-primary fw-bolder'
            >
              Connect To Metamask
            </button>
          )}
          {bc.account && (
            <button className='btn btn-custom btn-flex btn-color-white fw-bolder'>
              {shorttenAdd()}
            </button>
          )}
        </div>

        {/* begin::Theme mode */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div> */}
        {/* end::Theme mode */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={toAbsoluteUrl(currentUser?.pic ? currentUser.pic : '/media/avatars/blank.png')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
