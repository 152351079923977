import {FC} from 'react'
import {useAppSelector} from '../../../store/hook'

const StatusMessage: FC = () => {
  const bc: any = useAppSelector((state) => state.blockchain)

  return (
    <>
      {bc.isLoading && (
        <div className='alert alert-info' role='alert'>
          Initializing...
        </div>
      )}
      {!bc.isLoading && !bc.account && (
        <div className='alert alert-warning' role='alert'>
          Please connect your nft wallet.
        </div>
      )}
      {!bc.isLoading && bc.account && !bc.networkSupported && (
        <div className='alert alert-danger' role='alert'>
          Please change your network to ethereum mainnet
        </div>
      )}
      {!bc.isLoading && bc.account && bc.networkSupported && !bc.addressValid && (
        <div className='alert alert-danger' role='alert'>
          The address that you are using already binded to another account.
        </div>
      )}
    </>
  )
}

export {StatusMessage}
