import {createSlice} from '@reduxjs/toolkit'

interface dataState {
  loading: boolean
  name: string
  error: boolean
  errorMsg: string
}

const initialState: dataState = {
  loading: false,
  name: '',
  error: false,
  errorMsg: '',
}

const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    fetchDataRequest(state) {
      state.loading = true
    },
    fetchDataSuccess(state) {
      state.loading = false
    },
    fetchDataFailed(state, action) {
      state.loading = false
      state.error = true
      state.errorMsg = action.payload
    },
  },
})

export const {fetchDataRequest, fetchDataSuccess, fetchDataFailed} = dataSlice.actions

export default dataSlice.reducer
