import {Suspense, useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {useAppSelector} from './store/hook'

const App = () => {
  const ts: any = useAppSelector((state) => state.toast)
  const [show, setShow] = useState(false)
  const [firstToast, setFirstToast] = useState(true)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ToastContainer className='p-3' position='top-end'>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={ts.secondToClose}
              autohide
              bg={ts.theme}
            >
              <Toast.Header>
                <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
                <strong className='me-auto'>{ts.title}</strong>
              </Toast.Header>
              <Toast.Body>{ts.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
