import {Modal, Button} from 'react-bootstrap'

const CollectionConfigModal = (props: any) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          The Bulat-Bulat Collection *dummy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id='kt_modal_add_user_form' className='form' noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <h4 className='mb-5 text-info'>Sale Setting</h4>
            <div className='row'>
              {/* begin::Input group */}
              <div className='fv-row mb-7 col-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>MSRP Price</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert MSRP Price'
                  defaultValue='299'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  type='text'
                  name='msrpPrice'
                  autoComplete='off'
                />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'></span>
                  </div>
                </div>
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7 col-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Whole Sale Price</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert Whole Sale Price'
                  defaultValue='299'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  type='text'
                  name='wholesalePrice'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}
            </div>

            <div className='row'>
              {/* begin::Input group */}
              <div className='fv-row mb-7 col-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Minimum Advertised Price</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert Minimum Advertised Price'
                  defaultValue='50'
                  type='text'
                  name='minimumAdvertisedPrice'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}
            </div>

            <h4 className='mb-5 text-info'>Shipping Dimension</h4>

            <div className='row'>
              {/* begin::Input group */}
              <div className='fv-row mb-7 col-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Length</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert length'
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  name='length'
                  defaultValue='30'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7 col-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Width</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert Width'
                  type='text'
                  defaultValue='30'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  name='width'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}
            </div>

            <div className='row'>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Height</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert Height'
                  type='text'
                  defaultValue='30'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  name='height'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Weight</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Insert Weight'
                  defaultValue='50'
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  name='weight'
                  autoComplete='off'
                />
                {/* end::Input */}
                <div className='fv-plugins-message-container'>
                  <span role='alert'></span>
                </div>
              </div>
              {/* end::Input group */}
            </div>
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <p>Note: This will overwrite all the nft setting in this collection.</p>
            <button type='reset' className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
              Discard
            </button>

            <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
              <span className='indicator-label'>Save</span>
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
          {/* end::Actions */}
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CollectionConfigModal
