import {createSlice} from '@reduxjs/toolkit'

interface toastState {
  title: string
  message: string
  theme: string
  secondToClose: number
}

const initialState: toastState = {
  title: '',
  message: '',
  theme: 'alert',
  secondToClose: 3000,
}

const toastSlice = createSlice({
  name: 'toast',
  initialState: initialState,
  reducers: {
    showToast(state, action) {
      state.title = action.payload.title
      state.message = action.payload.message
      state.theme = action.payload.theme
      state.secondToClose = action.payload.secondToClose
    },
  },
})

export const {showToast} = toastSlice.actions

export default toastSlice.reducer
