import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {OwnerNFTListWrapper} from '../pages/owner/owner-nft-list'
import {OwnerNFTEarningWrapper} from '../pages/owner/owner-nft-earning'
import {EditorCollectionWrapper} from '../pages/editor/editor-collections'
import {EditorNFTListWrapper} from '../pages/editor/editor-nft-list'
import {AccountAddressWrapper} from '../modules/accounts/AccountAddressWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='owner-nft' element={<OwnerNFTListWrapper />} />
        <Route path='owner-nft/:nftId/earning' element={<OwnerNFTEarningWrapper />} />
        <Route path='editor-collections' element={<EditorCollectionWrapper />} />
        <Route path='editor-nft/:collectionId' element={<EditorNFTListWrapper />} />
        <Route path='account/addresses' element={<AccountAddressWrapper />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
