import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget10, MixedWidget11} from '../../../_metronic/partials/widgets'
import {StatusMessage} from '../../modules/errors/components/StatusMessage'
import {useAppSelector} from '../../store/hook'

const DashboardPage = () => (
  <>
    <label>*Dummy data</label>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const bc: any = useAppSelector((state) => state.blockchain)

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <StatusMessage />
      {bc.networkSupported && bc.addressValid && <DashboardPage />}
    </>
  )
}

export {DashboardWrapper}
