import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {StatusMessage} from '../../modules/errors/components/StatusMessage'
import {WithChildren} from '../../../_metronic/helpers'
import {fetchOwnerNFT, initAssets, verifyWalletAddress} from '../../store/nft/nft-actions'
import {useAppSelector, useAppDispatch} from '../../store/hook'

const OwnerNFTListPage: FC = () => {
  const dispatch = useAppDispatch()
  const nft: any = useAppSelector((state) => state.nft)
  const bc: any = useAppSelector((state) => state.blockchain)

  let timer: NodeJS.Timeout | null = null

  useEffect(() => {
    if (bc.account) {
      dispatch(verifyWalletAddress(bc.account))
    }
  }, [bc, dispatch])

  useEffect(() => {
    if (nft.walletInitialized !== null) {
      if (nft.walletInitialized) {
        if (timer) {
          clearTimeout(timer)
        }
        dispatch(fetchOwnerNFT(bc.account, 50, 0))
      } else {
        dispatch(initAssets(bc.account))
        timer = setInterval(() => {
          dispatch(verifyWalletAddress(bc.account))
        }, 10000)
      }
    }
  }, [dispatch, nft.walletInitialized])

  return (
    <div className='row'>
      {!nft.walletInitialized && <h4>initializing your wallet address, please wait...</h4>}
      {nft.walletInitialized && nft.assets.length === 0 && (
        <div className='alert alert-info' role='alert'>
          No supported NFTs found in this wallet address
        </div>
      )}
      {nft.assets.length > 0 &&
        nft.assets.map((item: any) => (
          <div className='col-12 col-md-6 col-lg-4 p-5' key={item._id}>
            <div className=' d-flex justify-content-center'>
              <img src={item.image_url} width='100%' alt='' />
            </div>
            <div className='row'>
              <div className='col-6 pt-3'>
                <h4 className='pt-3'>{item.name}</h4>
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <Link to={`/owner-nft/${item._id}/earning`} className='btn btn-primary btn-sm mt-3'>
                  View Earning
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

const OwnerNFTListWrapper: FC<WithChildren> = () => {
  const bc: any = useAppSelector((state) => state.blockchain)

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OWNER_NFT'})}</PageTitle>
      <StatusMessage />
      {bc.networkSupported && bc.addressValid && <OwnerNFTListPage />}
    </>
  )
}

export {OwnerNFTListWrapper}
