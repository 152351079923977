import {FC, useEffect, useState} from 'react'
// import {RootState} from '../../../setup/redux/RootReducer'
import {KTSVG} from '../../../_metronic/helpers'
import {StatusMessage} from '../errors/components/StatusMessage'
// import * as nftReducer from '../../../setup/redux/nft/nftReducer'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import React from 'react'
import {fetchBindedAddress, unbindAddress} from '../../store/nft/nft-actions'
import {useAppDispatch, useAppSelector} from '../../store/hook'

const DashboardPage: FC = () => {
  const dispatch = useAppDispatch()
  const nft: any = useAppSelector((state) => state.nft)
  const bc: any = useAppSelector((state) => state.blockchain)

  useEffect(() => {
    dispatch(fetchBindedAddress())
  }, [nft.addresses, dispatch])

  const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedAddressId, setSelectedAddressId] = useState('')

  const [modalShow, setModalShow] = React.useState(false)
  const handleClose = () => setModalShow(false)
  const handleOpen = (id: string, address: string) => {
    setSelectedAddress(address)
    setSelectedAddressId(id)
    setModalShow(true)
  }
  const handleConfirm = () => {
    dispatch(unbindAddress(selectedAddressId))
    setModalShow(false)
  }

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Wallet Address</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {/* Total {nft.addresses.length} address(es) have been binded to this account */}
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Address</th>
                  <th className='min-w-140px'>Date Binded</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {nft.addresses.map((address: any) => (
                  <tr key={address._id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {address.address}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {moment(address.created_at).format('DD MMM YYYY')}
                      </span>
                    </td>
                    <td>
                      {bc.account === address.address && (
                        <div className='d-flex text-info justify-content-end flex-shrink-0'>
                          Connected
                        </div>
                      )}
                      {bc.account !== address.address && (
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-color-warning btn-active-color-primary btn-sm'
                            onClick={() => {
                              handleOpen(address._id, address.address)
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={handleClose}
              id={selectedAddressId}
              address={selectedAddress}
              onConfirm={handleConfirm}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

const MyVerticallyCenteredModal = (props: any) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Unbind <b>{props.address}</b> from your account?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button size='sm' variant='outline-light' onClick={props.onHide}>
          Cancel
        </Button>
        <Button size='sm' variant='danger' onClick={props.onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const AccountAddressWrapper: FC = () => {
  const bc: any = useAppSelector((state) => state.blockchain)

  return (
    <>
      <StatusMessage />
      {bc.networkSupported && bc.addressValid && <DashboardPage />}
    </>
  )
}

export {AccountAddressWrapper}
