// import Web3 from 'web3'
import {
  connectFailed,
  connectRequest,
  connectSuccess,
  setAddressInvalid,
  setAddressValid,
  setChainNotSupported,
  setChainSupported,
  updateAccountRequest,
} from './blockchain-slice'
import {fetchData} from '../data/data-actions'
import axios from 'axios'

// const {Web3} = window as any
const API_URL = process.env.REACT_APP_API_URL

export const init = () => {
  return async (dispatch: any) => {
    dispatch(connectRequest())
    if (window.ethereum) {
      // let web3 = new Web3(window.ethereum)
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        const networkId = await window.ethereum.request({
          method: 'eth_chainId',
        })

        if (accounts.length > 0) {
          dispatch(
            connectSuccess({
              account: accounts[0],
              // web3: web3,
              web3: null,
            })
          )

          dispatch(verifyWalletAddress(accounts[0]))

          if (networkId !== process.env.REACT_APP_WEB3_NETWORK_CHAINID) {
            dispatch(setChainNotSupported())
            // alert('Please only use the ' + process.env.REACT_APP_WEB3_NETWORK_NAME);
            //window.location.reload(); //replace with cleaRCACHE
          } else {
            dispatch(setChainSupported())
          }
        }

        // Add listeners start
        window.ethereum.on('accountsChanged', (accounts: any[]) => {
          dispatch(updateAccount(accounts[0]))
          dispatch(verifyWalletAddress(accounts[0]))
        })
        window.ethereum.on('chainChanged', (networkId: string) => {
          if (networkId !== process.env.REACT_APP_WEB3_NETWORK_CHAINID) {
            dispatch(setChainNotSupported())
          } else {
            dispatch(setChainSupported())
          }
        })
      } catch (err) {
        dispatch(connectFailed('Something went wrong.'))
      }
    }
  }
}

export const connect = () => {
  return async (dispatch: any) => {
    dispatch(connectRequest())
    if (window.ethereum) {
      // let web3 = new Web3(window.ethereum)
      let networkSupported = false
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        // const networkId = await window.ethereum.request({
        //   method: 'eth_chainId',
        // })
        // const NetworkData = await SmartContract.networks[networkId];
        // if (NetworkData) {
        //   const SmartContractObj = new web3.eth.Contract(
        //     SmartContract.abi,
        //     NetworkData.address
        //   );
        dispatch(
          connectSuccess({
            account: accounts[0],
            networkSupported: networkSupported,
            web3: null,
          })
        )
        dispatch(verifyWalletAddress(accounts[0]))
      } catch (err) {
        dispatch(connectFailed('Something went wrong.'))
      }
    } else {
      dispatch(connectFailed('Install Metamask.'))
    }
  }
}

export const updateAccount = (account: any) => {
  return async (dispatch: any) => {
    dispatch(updateAccountRequest({account: account}))
    dispatch(fetchData(account))
  }
}

export const verifyWalletAddress = (address: string) => {
  return async (dispatch: any) => {
    const verify = async () => {
      const response = await axios.post(`${API_URL}/web3/wallet-verify`, {address})
      if (response.status !== 200) {
        throw new Error('Failed to load data')
      }

      return response.data
    }

    try {
      const data = await verify()
      if (data.status) {
        dispatch(setAddressValid())
      } else {
        dispatch(setAddressInvalid())
      }
    } catch (err) {
      console.log(err)
    }
  }
}
