/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {Badge} from 'react-bootstrap'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl(currentUser?.pic ? currentUser.pic : '/media/avatars/blank.png')}
            />
          </div>

          {currentUser && (
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.first_name} {currentUser?.last_name}
                {currentUser?.roles?.findIndex((role) => (role as unknown) === 'adm') >= 0 && (
                  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                    Sys Admin
                  </span>
                )}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
              <div>
                {currentUser?.roles?.findIndex((role) => (role as unknown) === 'own') >= 0 && (
                  <>
                    <Badge bg='info' className='mt-2'>
                      Owner
                    </Badge>{' '}
                  </>
                )}
                {currentUser?.roles?.findIndex((role) => (role as unknown) === 'edi') >= 0 && (
                  <>
                    <Badge bg='warning' className='mt-2'>
                      Editor
                    </Badge>{' '}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/account/addresses'} className='menu-link px-5'>
          Wallet Address
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
