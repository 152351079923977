import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const {currentUser} = useAuth()

  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {currentUser && currentUser.roles?.findIndex((role) => (role as unknown) === 'own') >= 0 && (
        <MenuItem title='My Owned NFTs' to='/owner-nft' />
      )}
      {currentUser && currentUser.roles?.findIndex((role) => (role as unknown) === 'edi') >= 0 && (
        <MenuItem title='Managing NFTs' to='/editor-collections' />
      )}
    </>
  )
}
