import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
// import {RootState} from '../../../setup'
import {PageTitle} from '../../../_metronic/layout/core'
// import * as nftReducer from '../../../setup/redux/nft/nftReducer'
import {Link} from 'react-router-dom'
import React from 'react'
import CollectionConfigModal from './modals/collectionConfigModal'
import {StatusMessage} from '../../modules/errors/components/StatusMessage'
import {fetchEditorCollections, initAssets, verifyWalletAddress} from '../../store/nft/nft-actions'
import {useAppSelector, useAppDispatch} from '../../store/hook'

const EditorCollectionPage: FC = () => {
  const dispatch = useAppDispatch()
  const nft: any = useAppSelector((state) => state.nft)
  const bc: any = useAppSelector((state) => state.blockchain)

  let timer: NodeJS.Timeout | null = null

  const [modalShow, setModalShow] = React.useState(false)

  useEffect(() => {
    if (bc.account) {
      dispatch(verifyWalletAddress(bc.account))
    }
  }, [bc, dispatch])

  useEffect(() => {
    if (nft.walletInitialized !== null) {
      if (nft.walletInitialized) {
        if (timer) {
          clearTimeout(timer)
        }
        dispatch(fetchEditorCollections(bc.account))
      } else {
        dispatch(initAssets(bc.account))
        timer = setInterval(() => {
          dispatch(verifyWalletAddress(bc.account))
        }, 10000)
      }
    }
  }, [dispatch, nft.walletInitialized])

  return (
    <>
      <div className='row'>
        {!nft.walletInitialized && <h4>Initializing your wallet address, please wait...</h4>}
        {nft.walletInitialized && nft.collections.length === 0 && (
          <div className='alert alert-info' role='alert'>
            No collection found in this wallet address
          </div>
        )}
        {nft.walletInitialized &&
          nft.collections.map((item: any) => (
            <div className='card mb-2' key={item._id}>
              <img src={item.banner_image_url} className='card-img-top' alt='...'></img>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-8'>
                    <h2 className='card-title'>{item.name}</h2>
                    <Link to={`/editor-nft/${item._id}`} className='btn btn-primary'>
                      View NFTs
                    </Link>
                  </div>
                  <div className='col-4'>
                    <div className='d-flex justify-content-end'>
                      <div className='p-2'>
                        <a
                          href='#'
                          className='btn btn-secondary mb-3'
                          onClick={() => setModalShow(true)}
                        >
                          Setting
                        </a>
                      </div>
                      <div className='p-2'>
                        <div className='form-check form-check-solid form-switch fv-row pt-2'>
                          <input
                            className='form-check-input w-45px h-30px'
                            type='checkbox'
                            id='allowmarketing'
                            defaultChecked={true}
                          />
                          <label className='form-check-label pt-1'>On Sale</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CollectionConfigModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

const EditorCollectionWrapper: FC = () => {
  const bc: any = useAppSelector((state) => state.blockchain)

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>NFT Collections</PageTitle>
      <StatusMessage />
      {bc.networkSupported && bc.addressValid && <EditorCollectionPage />}
    </>
  )
}

export {EditorCollectionWrapper}
