import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card} from 'react-bootstrap'
import {NFTSale} from './nft-sale'
import {fetchNFT} from '../../store/nft/nft-actions'
import {useAppSelector, useAppDispatch} from '../../store/hook'

const OwnerNFTEarningWrapper: FC = () => {
  const params = useParams()
  const nftId = params.nftId ? params.nftId.toString() : ''
  const dispatch = useAppDispatch()
  const nft: any = useAppSelector((state) => state.nft)
  const bc: any = useAppSelector((state) => state.blockchain)
  useEffect(() => {
    if (bc.account) {
      dispatch(fetchNFT(bc.account, nftId))
    }
  }, [bc, dispatch])

  const intl = useIntl()
  return (
    <>
      <label>*Dummy data</label>
      {nft.asset && (
        <div>
          <PageTitle breadcrumbs={[]}>{nft.asset.name}</PageTitle>
          <div className='row p-5'>
            <NFTSale
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              chartHeight='150px'
            ></NFTSale>
          </div>
        </div>
      )}
    </>
  )
}

export {OwnerNFTEarningWrapper}
